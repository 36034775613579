import React, { useState, useEffect, useRef } from "react"

export const setLang2 = ():string => {
  const [lang, setLang] = useState<string>('ja')
  const langRef = useRef<string>(lang)

  const setWovnLangage = () => {
    if(window.WOVN && WOVN.io && WOVN.io.isApiReady()) {
      const newLang = window.WOVN.io.getCurrentLang().code
      setLang(newLang)
      langRef.current = newLang
    } else {
      langRef.current = 'ja'
    } 
  }

  useEffect(() => {
    setTimeout(() => {
      // 初期表示
      setWovnLangage()
      // 言語切り替え時
      window.addEventListener('wovnLangChanged', setWovnLangage)
      // cleanup処理
      return () => window.removeEventListener('wovnLangChanged', setWovnLangage)
    }, 500)

  }, []);

  return langRef.current
}